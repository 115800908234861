import axios from 'axios';

let CancelToken = axios.CancelToken;
let cancelTokens = {};

const addCancelToken = (url) => {
    const key = generateKey(url);
    cancelTokens[key] = CancelToken.source();
};

const getCancelToken = (url) => {
    const key = generateKey(url);

    return cancelTokens[key] ?
        cancelTokens[key].token :
        null;
};

const cancelRequest = (url) => {
    const key = generateKey(url);

    if (cancelTokens[key]) {
        cancelTokens[key].cancel(url + ' cancelled');
        removeCancelToken(url);
    }
};

const removeCancelToken = (url) => {
    const key = generateKey(url);

    if (cancelTokens[key]) {
        cancelTokens[key] = undefined;
    }
};

const generateKey = (url) => {
    return url.replace(/\//g, '');
}

export const UtilApi = {
    addCancelToken,
    cancelRequest,
    getCancelToken,
    removeCancelToken
};

document.addEventListener('cancelRequests', (e) => {
    const { requests } = e.detail;

    requests.forEach((request) => {
        cancelRequest(request);
    });
});