import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';

class SidebarUserBlock extends Component {
    render() {
        const { client } = this.props;

        if (!client) {
            return null;
        }

        return (
            <Collapse id="user-block" isOpen={true}>
                <div>
                    <div className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                <img className="img-thumbnail rounded-circle" src={client.image_small ? client.image_small : 'images/dummy.png'} alt="Avatar" width="60" height="60" />
                            </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info">
                            <span className="user-block-name">
                                {client.fullName}
                            </span>
                            <span className="user-block-role">
                                {this.props.group.name}
                            </span>
                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool
};

const mapStateToProps = state => ({ showUserBlock: state.settings.showUserBlock, client: state.client, group: state.group })

export default connect(
    mapStateToProps
)(SidebarUserBlock);