/**
 * Client actions
 */
export const CLIENT_LOGGED_IN = 'CLIENT_LOGGED_IN';
export const CLIENT_LOGGED_OUT = 'CLIENT_LOGGED_OUT';

export function clientLoggedIn(value) {
    return { type: CLIENT_LOGGED_IN, value };
};

export function clientLoggedOut() {
    return { type: CLIENT_LOGGED_OUT };
};

export const CLIENT_SET = 'CLIENT_SET';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_UPDATE_SETTINGS = 'CLIENT_UPDATE_SETTINGS';
export const CLIENT_REMOVE = 'CLIENT_REMOVE';

export function clientSet(value) {
    return { type: CLIENT_SET, value };
};

export function clientUpdate(value) {
    return { type: CLIENT_UPDATE, value };
};

export function clientUpdateSettings(value) {
    return { type: CLIENT_UPDATE_SETTINGS, value };
};

export function clientRemove() {
    return { type: CLIENT_REMOVE };
};