import { SCHEMA_DATA_LOADED, SCHEMA_DATA_UNLOADED, SCHEMA_DATA_UPDATED, SCHEMA_DATA_REMOVED } from './schema-data.actions';

const schemaDataReducer = (state = null, action) => {
    switch (action.type) {
        case SCHEMA_DATA_LOADED:
            return action.value;
        case SCHEMA_DATA_UNLOADED:
            return null;
        case SCHEMA_DATA_UPDATED:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    [action.id]: action.value
                }
            };
        case SCHEMA_DATA_REMOVED:
            return {
                ...state,
                [action.key]: {
                    ...state[action.key],
                    [action.id]: undefined
                }
            };
        default:
            return state;
    }
}

export default schemaDataReducer;