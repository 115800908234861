import { SET_PATIENT } from './patient.actions';

const patientReducer = (state = null, action) => {
    switch (action.type) {
        case SET_PATIENT:
            return action.value;
        default:
            return state;
    }
}

export default patientReducer;