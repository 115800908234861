import { API } from '../../services/api';
import { CALLCENTER_ACCESS_KEY_SET, CALLCENTER_ACCESS_KEY_REMOVE } from './callcenter.actions';

/*
   Hook into setting changes in order to change layout.
*/
const callCenter = store => next => action => {
    let result = next(action)

    if (action.type === CALLCENTER_ACCESS_KEY_SET) {
        API.setToken(action.value);
    } else if (action.type === CALLCENTER_ACCESS_KEY_REMOVE) {
        API.setToken(null);
    }

    /*if (action.name === 'logged_in' && (action.type === CALLCENTER_SET || action.type === CALLCENTER_UPDATE)) {
        i18n.changeLanguage(action.value.nativeLanguage);
    }*/

    return result
}

export default callCenter;