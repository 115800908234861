import { combineReducers } from 'redux';

import callCenterReducer from './callcenter/callcenter.reducer';
import clientReducer from './client/client.reducer';
import groupReducer from './group/group.reducer';
import notificationsReducer from './notifications/notifications.reducer';
import patientReducer from './patient/patient.reducer';
import schemaDataReducer from './schema-data/schema-data.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
    callCenter: callCenterReducer,
    client: clientReducer,
    group: groupReducer,
    schemaData: schemaDataReducer,
    notifications: notificationsReducer,
    patient: patientReducer,
    settings: settingsReducer
});