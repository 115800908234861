import routes from "../routing/routes";
import { reverse } from "named-urls";

const MenuSetting = [
    {
        name: 'Terug naar agenda',
        icon: 'fa fa-arrow-left',
        translate: 'sidebar:settings:agenda-back',
        path: reverse(routes.myagenda),
    },
    {
        name: 'Instellingen',
        icon: 'fa fa-cog',
        translate: 'sidebar:settings:main',
        submenu: [{
            name: 'holidays',
            translate: 'sidebar:settings:holidays',
            path: reverse(routes.holidays.overview)
        },
        {
            name: 'exceptions',
            translate: 'sidebar:settings:exceptions',
            path: reverse(routes.exceptions.overview)
        },
        {
            name: 'blockings',
            translate: 'sidebar:settings:blockings',
            path: routes.blockings.overview
        }]
    }];

export default MenuSetting;