import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LocalStorageService } from './../services/local-storage.service.js';

class FlashMessage extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        this.showFlashMessage();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return null; // <ToastContainer />;
    }

    showFlashMessage = () => {
        toast.dismiss();
        
        const flashMsg = LocalStorageService.get('FlashMessage');

        if (flashMsg) {
            LocalStorageService.remove('FlashMessage');

            let message = '';
            if (typeof flashMsg.message === 'string') {
                message = flashMsg.message;
            } else {
                for (let key in flashMsg.message) {
                    message += flashMsg.message[key] + '\n';
                }
            }

            toast(message, { type: flashMsg.status, position: toast.POSITION.TOP_RIGHT });
        }
    }
}

export default FlashMessage;