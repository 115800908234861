import { UtilUrl } from "./url.util";
import routes from '../routing/routes';

const asembleSidebar = (group, loggedinClient = null) => {
    let menu = [];

    menu.push({
        name: 'Patiënten',
        icon: 'icon-people',
        translate: 'sidebar:patients:main',
        path: UtilUrl.generate(routes.patients.overview)
    });


    let agendaMenu = {
        name: 'Agenda',
        icon: 'icon-calendar',
        translate: 'sidebar:agenda:main',
        submenu: []
    };
    
    agendaMenu['submenu'].push(
        {
            name: 'Groepsoverzicht',
            translate: 'sidebar:agenda:groupoverview',
            path: UtilUrl.generate(routes.group)

        });

    agendaMenu['submenu'].push(
        {
            name: 'Huisbezoeken',
            translate: 'sidebar:agenda:homevisits',
            path: UtilUrl.generate(routes.homevisits.overview)
        }
    );

    if (group && group.subgroups && group.subgroups.length > 0) {
        group.subgroups.forEach((subgroup) => {

            agendaMenu['submenu'].push({
                name: subgroup.name,
                translate: subgroup.name,
                path: UtilUrl.generate(routes.subgroup, { subgroup: subgroup.id })
            });
        });
    }

    if (group && group.clients && group.clients.forEach((client) => {
        agendaMenu['submenu'].push({
            name: client.fullName,
            translate: client.fullName,
            path: UtilUrl.generate(routes.myagenda, { client: client.id })
        });
    }));

    agendaMenu['submenu'].push(
        {
            name: 'Zoek vrij tijdstip',
            translate: 'sidebar:agenda:searchfreeslot',
            path: UtilUrl.generate(routes.agenda.searchfreeslot)
        },
        {
            name: 'Dagafspraken',
            translate: 'sidebar:agenda:dayappointments',
            path: UtilUrl.generate(routes.dayappointments.overview)
        },
        {
            name: 'Historiek',
            translate: 'sidebar:agenda:history',
            path: UtilUrl.generate(routes.agenda.history)
        },
        {
            name: 'Conflicten',
            translate: 'sidebar:agenda:conflicts',
            path: UtilUrl.generate(routes.agenda.conflicts),
            label: 'appointmentConflicts'
        }
    );

    menu.push(agendaMenu);

    
    return menu;
};

export const UtilSidebar = {
    asembleSidebar
};