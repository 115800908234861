export const GROUP_UPDATE = 'GROUP_UPDATE';
export const GROUP_REMOVE = 'GROUP_REMOVE';

/**
 * Change a group
 * payload.value: new value to apply
 */
export function groupUpdate(value) {
    return { type: GROUP_UPDATE, value };
}

export function groupRemove() {
    return { type: GROUP_REMOVE };
}