import { withRouter } from 'react-router';
import axios from 'axios';
import i18next from 'i18next';
import routes from '../routing/routes';
import configureStore from '../store/store';
import { UtilApi } from '../utils/api.util';

let authToken = null;
const setToken = (token) => {
    authToken = token;
};

let sessionId = null;
const setSessionId = (id) => {
    sessionId = id;
};

const state = configureStore().getState();

const client = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.trim() + '/' + process.env.REACT_APP_API_VERSION + '/',
    headers: {
        'Content-Type': 'application/json'
    }
});

const get = (url, params) => {
    return request({
        url: url,
        method: 'GET',
        params: params,
        headers: {}
    });
};

const remove = (url, params) => {
    return request({
        url: url,
        method: 'DELETE',
        params: params,
        headers: {}
    });
};

const post = (url, data, headers = {}) => {
    return request({
        url: url,
        method: 'POST',
        data: data,
        headers: headers
    });
};

const request = (requestOptions) => {
    //cancel && cancel();

    UtilApi.cancelRequest(requestOptions.url);

    UtilApi.addCancelToken(requestOptions.url)

    if (!authToken) {
        authToken = state.callCenter ? state.callCenter.access_key : null;
    }
    client.defaults.headers.common['Authorization'] = authToken;

    if (!sessionId) {
        sessionId = state.settings.sessionId ? state.settings.sessionId : null;
    }
    client.defaults.headers.common['X-Session'] = sessionId;

    if (requestOptions['headers']['Content-Type']) {
        client.defaults.headers.common['Content-Type'] = requestOptions['headers']['Content-Type'];
    }

    if (requestOptions['data']) {
        requestOptions['data']['lng'] = i18next.language;
    }

    // requestOptions['signal'] = abortController.signal;

    client.defaults.cancelToken = UtilApi.getCancelToken(requestOptions.url);

    // console.log(requestOptions);

    return client(requestOptions)
        .then((response) => {
            // console.log(response.status, response.statusText, ':', response.data.status, response.data.data);
            return response.data;
        }).catch((error) => {
            if (axios.isCancel(error)) {
                // console.log('Request aborted: ' + error);
                return false;
            } else {
                const { status } = error.response;

                // console.log(status);
                if (status === 401) {
                    // TODO: Show unauthorized message
                    // TODO: Check if token is valid; if not redirect to login
                    window.location = routes.auth.login;
                } else if (status === 500) {
                    // LocalStorageService.store('FlashMessage', {type: 'error', data: 'An error occured'});
                    // window.location = routes.error;
                }
            }
        }).then((response) => {
            UtilApi.removeCancelToken(requestOptions.url);

            return response;
        });
};

const isSuccess = (response) => {
    return response.status && response.status === 'success';
}

export const API = withRouter({ get, post, remove, isSuccess, setToken, setSessionId });