import { CALLCENTER_INITIALS_SET, CALLCENTER_INITIALS_REMOVE, CALLCENTER_ACCESS_KEY_SET, CALLCENTER_ACCESS_KEY_REMOVE } from './callcenter.actions';

const callCenterReducer = (state = null, action) => {
    switch (action.type) {
        case CALLCENTER_INITIALS_SET:
            return {
                ...state,
                initials: action.value
            }
        case CALLCENTER_INITIALS_REMOVE:
            return {
                ...state,
                initials: undefined
            }
        case CALLCENTER_ACCESS_KEY_SET:
            return {
                ...state,
                access_key: action.value
            }
        case CALLCENTER_ACCESS_KEY_REMOVE:
            return {
                ...state,
                access_key: undefined
            }
        default:
            return state;
    }
}

export default callCenterReducer;