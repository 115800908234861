import SecureLS from 'secure-ls';

const init = () => {
    return new SecureLS({encodingType: 'aes', encryptionSecret: process.env.REACT_APP_STORAGE_ENCRYPTION_KEY});
};

const get = (key) => {
    try {
        return init().get(key);
    } catch {
        return null;
    }

};

const store = (key, data) => {
    init().set(key, data);
};

const remove = (key) => {
    init().remove(key);
};

const clear = () => {
    init().removeAll();
};

export const LocalStorageService = {store, get, remove, clear};