import { include } from 'named-urls';

export default {
    home: '/',
    dashboard: '/',
    myagenda: '/agenda/:client?/:date?',
    group: '/groupoverview/:date?',
    subgroup: '/subgroup/:subgroup?/:date?',

    notfound: '/404-not-found',
    error: '/500-error',

    auth: {
        login: '/login',
        forgotpassword: '/forgot-password',
        resetpasswordcode: '/reset-password/:code',
        resetpassword: '/reset-password'
    },

    agenda: {
        history: '/history/:date?/:start?/:end?',
        searchfreeslot: '/searchfreeslot',
        conflicts: '/conflicts',

        printWeek: '/agenda/print/week/:date/:client?',
        printDay: '/agenda/print/day/:date/:client?',

        appointmentCopy: '/agenda/copy/:appointment/:date/:client?',
        appointmentMove: '/agenda/move/:appointment/:date/:client?',

        appointmentMoveClient: '/groupoverview/move/:client/:appointment/:date',
        groupPrint: '/groupoverview/print/:date'
    },

    appointments: include('/appointments', {
        overview: '',
        new: 'new/:client/:start?/:end?',
        add: 'add/:client/:start?/:end?/:category?/:subcategory?',
        edit: 'edit/:client/:id',
        remove: 'remove/:id',
        print: 'print/:id',
        search: 'search/:searchStr/:client?',
        statistics: include('statistics', {
            overview: ''
        })
    }),

    notes: include('/notes', {
        overview: '',
        add: 'add/:client/:start/:allDay?',
        edit: 'edit/:client/:id'
    }),

    homevisits: include('/homevisit', {
        overview: ':for?/:date?',
        add: 'add/:date?/:client?/:time?/:category?',
        edit: 'edit/:id'
    }),

    dayappointments: include('/dayappointments', {
        overview: '',
        add: 'add/:date?',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    patients: include('/patients', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        appointments: 'appointments/:id/:repeated?',
        mail: 'mail/:id',
        confirm: 'confirm'
    }),

    holidays: include('/settings/holidays', {
        overview: ':type?',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        holidays: 'holidays'
    }),

    profiles: include('/settings/profiles', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        compose: 'compose/:id/:wizard?',
        priority: 'priority/:id',
        homevisit: 'homevisit/:id',
        remove: 'remove/:id',
        periods: include('periods/:scheduleId', {
            overview: '',
            add: 'add/:wizard?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        })
    }),

    appointmentstatuses: include('/settings/appointmentstatuses', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    exceptions: include('/settings/exceptions', {
        overview: ':type?',
        add: 'add/:date?/:slot?/:client?',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    blockings: include('/settings/blockings', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    categories: include('/settings/categories', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    subcategories: include('/settings/subcategories', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    categoryforms: include('/settings/forms', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',

        fields: include(':form/field', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
        })
    }),

    /* TOOLS */
    tasks: include('/tasks', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    reminders: include('/reminders', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    popups: include('/popups', {
        overview: '',
        add: 'add/:client?',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    mailbox: include('/mailbox', {
        inbox: ':message?',
        compose: 'compose/:message?/:all?'
    }),

    settings: include('/settings', {
        personal: 'personal',
        agenda: 'agenda',
        patient: 'patient',
        links: 'links',
        groupSort: 'groupsort',
        account: include('account', {
            profile: 'profile'
        })
    }),

    communication: include('/communication', {
        mail: include('mail', {
            compose: '',
            templates: include('templates', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',

                timings: include(':template/timings', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
        sms: include('sms', {
            compose: '',
            numbers: 'numbers',
            templates: include('templates', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',

                timings: include(':template/timings', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
    }),

    e: include('/e', {
        al: ':path',
        c: 'c/:path',
        g: 'g/:path',
        t: 't/:path',
        gc: 'gc/:path',        
    })

    // e: '/e/:type/:path'
    // patientApp: '/e/:type/:path'
}