export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED';
export function notificationsLoaded(value) {
    return { type: NOTIFICATIONS_LOADED, value };
};

/**
 * Messages actions
 */
export const NOTIFICATIONS_MESSAGE_LOADED = 'NOTIFICATIONS_MESSAGE_LOADED';
export const NOTIFICATIONS_MESSAGE_READ = 'NOTIFICATIONS_MESSAGE_READ';
export const NOTIFICATIONS_MESSAGE_UNREAD = 'NOTIFICATIONS_MESSAGE_UNREAD';
export const NOTIFICATIONS_MESSAGE_REMOVE = 'NOTIFICATIONS_MESSAGE_REMOVE';

export function notificationsMessageLoaded(value) {
    return { type: NOTIFICATIONS_MESSAGE_LOADED, value };
};
export function notificationsMessageRead() {
    return { type: NOTIFICATIONS_MESSAGE_READ };
};
export function notificationsMessageUnread() {
    return { type: NOTIFICATIONS_MESSAGE_UNREAD };
};
export function notificationsMessageRemove() {
    return { type: NOTIFICATIONS_MESSAGE_REMOVE };
};

/**
 * Tasks actions
 */
export const NOTIFICATIONS_TASK_LOADED = 'NOTIFICATIONS_TASK_LOADED';

export function notificationTasksLoaded(value) {
    return { type: NOTIFICATIONS_TASK_LOADED, value };
};

/**
 * Reminder actions
 */
export const NOTIFICATIONS_REMINDERS_LOADED = 'NOTIFICATIONS_REMINDERS_LOADED';

export function notificationsRemindersLoaded(value) {
    return { type: NOTIFICATIONS_REMINDERS_LOADED, value };
};

/**
 * Conflict actions
 */
export const NOTIFICATIONS_APPOINTMENT_CONFLICTS_LOADED = 'NOTIFICATIONS_APPOINTMENT_CONFLICTS_LOADED';

export function notificationConflictsLoaded(value) {
    return { type: NOTIFICATIONS_APPOINTMENT_CONFLICTS_LOADED, value };
}